<template>
  <div class="main_wrapper">
    <div class="title_wrapper">
      <el-tabs
          class="admin_tabs"
          v-model="editableTabsValue"
          type="card"
          @tab-click="gotoPage"
          closable
          @tab-remove="handleTagClose">
        <el-tab-pane
            v-for="item in tags"
            :key="item.name"
            :label="item.label"
            :name="item.name"
            :ref="item.name">
        </el-tab-pane>

      </el-tabs>
    </div>

    <div class="content-wrap"  v-if="tags.length">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>

    <div class="content-wrap"  v-if="!tags.length">
      <ch-layout>
        <template #default>
          <div v-loading="true" style="width: 100%; height: 100%"></div>
        </template>
      </ch-layout>
      <!--载入处理空白-->
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

const uniqueObj = (arr, t) => {
  let hash = {}
  arr.forEach((data)=>{
    hash[data[t]] = data;
  })
  let newArr = [];
  for(let key in hash) {
    newArr.push(hash[key]);
  }
  return newArr
}
export default {
  data() {
    return {
      editableTabsValue: '',
    }
  },
  computed: {
    ...mapGetters(['tags'])
  },
  mounted() {
    // 排除黑名单tags
    this.excludeTage(this.$route.name)
  },
  methods: {
    ...mapMutations(['saveTags']),
    ...mapActions(['excludeTage']),
    //标签关闭
    handleTagClose(tag) {
      let that = this
      if (this.tags.length > 1) {
        let index = that.tags.findIndex((item) => item.name == tag)
        let before_index = 0
        if (index > 1) {
          before_index = index - 1
        } else if (index == 0) {
          before_index = index;
        }
        that.saveTags(that.tags.filter((item) => item.name != tag))
        that.gotoPage(that.tags[before_index]);
      } else {
        if (tag !== "classicIndex") {
          this.saveTags([])
          that.$router.push({path: "/classicIndex"})
        } else {
          //去除element去掉第一个样式问题
          this.$refs.classicIndex[0].$parent.$children[0].isFocus = false;
        }
      }
    },
    //去对应页面
    gotoPage(targetName) {
      let that = this
      if (that.tags.length == 0) {
        that.$router.push({path: "/classicIndex"});
      } else if (targetName.name != this.$route.name) {
        for(let tag of that.tags){
          if(tag.name ==targetName.name){
            that.$router.push({name: targetName.name,query:tag.query,params:tag.params});
            break;
          }
        }
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (to, from) {
        if (to.name != "error") {
          this.editableTabsValue = to.name
          let obj = {
            name: to.name,
            label: to.meta.title,
            query: to.query,
            params: to.params,
          }
          let currentTags = JSON.parse(window.sessionStorage.getItem('menuTags')) || []
          const index = currentTags.findIndex((item) => item.name === to.name)
          if(index !== -1){
            currentTags[index] = obj
          }
          currentTags.push(obj)
          currentTags = uniqueObj(currentTags, "name")
          this.saveTags(currentTags)
        } else if (from.name == "error") {
          this.tags.length = 0;
          this.$router.push({path: "/error"})
        }
      },
    },

  }
}
</script>

<style lang="scss" scoped>

.main_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .content-wrap {
    flex: 1;
    overflow: auto;
    position: relative;
    height: 100%;

    > * {
      width: 100%;
      height: 100%;
    }
  }

  .title_wrapper {
    height: 40px;
  }

  .admin_tabs {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    ::v-deep .el-tabs__header {
      border-top: 0;
      margin: 0;
      background-color: #FFFFFF;
      box-shadow: 0 2px 2px rgb(0 0 0 / 8%);

      .el-tabs__nav {
        border: 0;
        overflow: hidden;
      }

    }
  }

}

</style>